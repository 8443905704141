import React, { Component } from 'react';
import './Carousel.scss';

class Carousel extends Component {
    constructor(props) {
        super(props);
        this.state = { width: 5, height: 5, scrollPosition: 0, seed: "zoe", title: "", titleState: "type", titlePtr: 0, titleWait: 30, titleCursor: false };
        this.calcScroll = this.calcScroll.bind(this);
        this.setTitle = this.setTitle.bind(this);
    }

    componentDidMount() {
        this.interval = setInterval(this.setTitle, 90);
        window.addEventListener('scroll', this.calcScroll);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    calcScroll() {
        var _window = window;
        var scrollPos = _window.scrollY;
        this.setState({
            scrollPosition: scrollPos / 100,
        });
    }
    updateSeed(e) {
        this.setState({
            seed: e.target.value,
        });
    }

    setTitle() {
        var titles = [
        "I am a Software Engineer" ,
        "My hobbies include Analogue Photography and Sound Engineering", 
        "I enjoy attending tech and photography meetups across London"]
        if (this.state.titleState === "back") {
            this.setState({ title: this.state.title.slice(0, -1), titleCursor: false })
            if (this.state.title.length === 0) {
                this.state.titlePtr + 1 < titles.length ? this.setState({ titleState: "type", titlePtr: this.state.titlePtr + 1 }) :
                    this.setState({ titleState: "type", titlePtr: 0 })

            }
        } else if (this.state.titleState === "type") {
            this.setState({ title: titles[this.state.titlePtr].slice(0, this.state.title.length + 1), titleCursor: false })
            if (this.state.title === titles[this.state.titlePtr]) {
                this.setState({ titleState: "wait" })
            }
        } else if (this.state.titleState === "wait") {
            if (this.state.titleWait > 0) {
                this.setState({ titleWait: this.state.titleWait - 1 });
                this.state.titleWait % 5 === 0 && this.setState({ titleCursor: !this.state.titleCursor })
            } else {
                this.setState({ titleWait: 30, titleState: "back" })
            }
        }
    }

    render() {
        const cursorClassName = "carousel-cursor" + (this.state.titleCursor ? " hidden" : "");
        return (
            <div className="carousel-wapper">
                <h2>{this.state.title}<span className={cursorClassName}>|</span></h2>
            </div>
        );
    }
}

export default Carousel;
