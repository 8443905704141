import React, { Component } from 'react';
import Trianglify from 'react-trianglify';
import './Background.scss';

class Background extends Component {
    constructor(props) {
        super(props);
        this.state = { width: 5, height: 5, scrollPosition: 0, seed:props.seed||"test" };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.calcScroll = this.calcScroll.bind(this);
    }
 
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('scroll', this.calcScroll);
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.calcScroll)
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    calcScroll() {
        var _window = window;
        var scrollPos = _window.scrollY;
        this.setState({
            scrollPosition: scrollPos / 100,
        });
    }

    render() {
        const {seed} = this.state
        return (
            <div className="background" >
                <Trianglify
                    width={this.state.width}
                    height={this.state.height}
                    cellSize={100}
                    seed={seed}
                />
            </div>
        );
    }
}

export default Background;
