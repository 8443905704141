import React, { Component } from 'react';
import Carousel from './Carousel';
import Background from './Background';
import Badges from './Badges'
import './Home.scss';

class Home extends Component {

    render() {
        return (
            <div className="home-wrapper" >
                <h1>
                    Joel Walker
                </h1>
                <Carousel />

                <Badges/>
                <Background seed={"zoe"} />
            </div>
        );
    }
}

export default Home;
