import React, { Component } from 'react';
import arroba from '../assets/arroba.png'
import linkedIn from '../assets/linkedin.png'
import github from '../assets/github.png'
import cv from '../assets/CVIcon.png'
import CVfile from '../assets/Joel_Walker_CV.pdf'
import './Badges.scss';

class Badges extends Component {
    render() {
        return (
            <div className="badges-wrapper" >
                <div className="badge-group">
                    <a href="mailto:hello@joelwalker.co.uk">
                        <img src={arroba} alt="arroba" />
                    </a>
                    <a href="https://www.linkedin.com/in/joel-walker/" rel="noopener noreferrer" target="_blank">
                        <img src={linkedIn} alt="linkedIn" />
                    </a>
                    <a href="https://www.github.com/joelw16" rel="noopener noreferrer" target="_blank">
                        <img src={github} alt="github" />
                    </a>
                    <a href={CVfile} download="Joel_Walker_CV.pdf">
                        <img src={cv} alt="arroba" />
                    </a>
                </div>
                <p>This December I am participating in the{' '}
                    <a href="https://adventofcode.com" rel="noopener noreferrer" target="_blank">Advent of Code</a> 2019.
                    Explore my solutions on <a href="https://github.com/JoelW16/AdventOfCode" rel="noopener noreferrer" target="_blank"> GitHub</a>.
                </p>
                <p>
                    Software and film photography portfolios will follow in the New Year.
                </p>
                <p>
                    Happy Holidays!
                </p>
            </div>

        );
    }
}

export default Badges;
